import { css, useTheme } from "@emotion/react";
import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import {
  Card,
  CardContent,
  CardTitle,
  CardMeta,
  Button,
  useThemeProps,
  Excerpt,
  handleComponentsProp,
  parseStyle,
  Time,
} from "@wsui/base";
import { omit } from "lodash/fp";
import React, { Fragment } from "react";

import PostTime from "./wsui/components/PostTime.jsx";

const DefaultExcerpt = Excerpt;
const DefaultButton = Button;

export default function DefaultPostCard(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "DefaultPostCard" });
  props = useThemeProps({ props, name: "PostCard" });
  let {
    as: Component = Card,
    post,
    components = {},
    dateFormat = {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    style = {},
    titleProps = { variant: "h4" },
    metaProps = {},
    // visibleFields,
    isFieldVisible,
    color,
    ...restProps
  } = omit(["visibleFields"], props);

  let { title, image, link, date, description } = post;

  let { Excerpt, Title, Meta } = handleComponentsProp(components, {
    Button: DefaultButton,
    Excerpt: DefaultExcerpt,
    Title: CardTitle,
    Meta: CardMeta,
  });

  // eslint-disable-next-line no-unused-vars
  let { ...restImageProps } = omit(["caption"], image);

  const isExpanded = isFieldVisible("image");
  return (
    <Fragment>
      <Component
        title={isFieldVisible("title") ? title : null}
        link={link}
        css={css`
          ${parseStyle(style, theme)}
        `}
        color={!isExpanded ? color : "transparent"}
        {...restProps}
      >
        <CardContent
          css={css`
            display: flex;
            align-items: start;
            ${isExpanded
              ? "flex-direction: column;"
              : "flex-direction: row; gap: 1em;"}
          `}
        >
          {!isExpanded && (
            <Meta {...metaProps}>
              <PostTime date={date} format={dateFormat} />
            </Meta>
          )}
          <div>
            <Title
              css={css`
                width: 100%;
                color: #000;
              `}
              {...titleProps}
              variant={isExpanded ? "h2" : "bodyBold"}
            />

            {isExpanded && (
              <Meta {...metaProps}>
                <p>
                  <Time date={date} format={dateFormat} />
                </p>
              </Meta>
            )}

            <Excerpt variant={isExpanded ? "body" : "description"}>
              {description}
            </Excerpt>
          </div>
        </CardContent>
      </Component>

      {isFieldVisible("image") ? <Image {...restImageProps} /> : null}
    </Fragment>
  );
}
