/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import { getPage } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import { InlineList, Clickable } from "@wsui/base";

import useProductsInTreeMenu from "../../hooks/useProductsInTreeMenu";

const DefaultLink = styled(Clickable)`
  text-decoration: inherit;
  color: inherit;
  &.wsui-is-interactive:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

function tranformMenu(data, { pages }) {
  return (data.wp?.menus?.nodes || []).map((menu) => ({
    ...menu,
    items: menu.menuItems.nodes.map((menuItem) => {
      let { connectedObject, label, description, url, target, ...rest } =
        menuItem;
      let { contentType: { node: { name: type = "custom" } = {} } = {}, id } =
        connectedObject || {};
      let content = type === "page" ? getPage(pages, id) : {};
      return {
        type,
        url,
        target: connectedObject && connectedObject.id ? target : "_blank",
        ...content,
        label,
        description: description || (content && content.description),
        items: connectedObject?.children?.nodes?.map(({ uri, label }) => {
          return { url: uri, label };
        }),
        ...rest,
      };
    }),
  }));
}
export default function HeaderHelpMenu({ activeSubMenu, onMouseLeave }) {
  const theme = useTheme();
  let items = useMenu("HEADER_TABS_MENU", { transform: tranformMenu })?.items;

  const allTopMenuUri = items.map((item) => item.uri);
  const products = useProductsInTreeMenu();
  const navItems = items.map((item) =>
    item.uri === "/produkter/"
      ? {
          ...item,
          items: products
            .map((product) => {
              return {
                url: `/products/${product.slug}/`,
                label: product.title,
              };
            })
            .filter((product) => allTopMenuUri.includes(product.url) === false),
        }
      : item,
  );

  if (!navItems?.length) return null;
  const activeItem = navItems?.find(
    (item) => item.url === activeSubMenu && item.show_sub_menu?.showSubMenu,
  );

  if (!activeItem || !activeItem?.items?.length) return null;
  return (
    <div
      css={css`
        background-color: ${theme.getColor("blue.breeze")};
        color: ${theme.getColor("paxxo")};
        font-size: 16px;
        font-family: HelveticaNeue;
        position: absolute;
        width: 100%;
        z-index: 1;

        &:before {
          height: 20px;
          width: 20px;
          content: "";
          position: absolute;
          top: -1px;
          left: 49%;
          transform: rotate(45deg) translateX(-50%);
          background-color: ${theme.getColor("blue.breeze")};
          z-index: -2;
        }
      `}
    >
      <InlineList
        overflow="hidden"
        spacing={[0]}
        align="center"
        onMouseLeave={onMouseLeave}
      >
        {activeItem?.items
          ?.sort((a, b) => a.label.localeCompare(b.label))
          .map(({ url, label, target }, index) => {
            return (
              <DefaultLink
                url={url}
                target={target}
                key={index}
                style={css`
                  display: flex;
                  padding: 1.5rem 1.5rem;

                  &:hover {
                    background-color: ${theme.getColor("blue")};
                    color: ${theme.getColor("white")};
                  }
                `}
              >
                {label}
              </DefaultLink>
            );
          })}
      </InlineList>
    </div>
  );
}
