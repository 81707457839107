import { css, useTheme } from "@emotion/react";
import {
  useThemeProps,
  Box,
  TypographyBlock,
  Section,
  InlineList,
  Button,
  Heading,
  generateUniqueId,
  Icon,
} from "@wsui/base";
import omit from "lodash/fp/omit";
import { Fragment, useState, React } from "react";

export default function DefaultTextModule(props) {
  props = useThemeProps({
    props,
    name: "MunicipioDefaultTextModule",
  });
  let {
    // eslint-disable-next-line no-unused-vars
    title: ignoredTitle,
    // eslint-disable-next-line no-unused-vars
    description,
    buttonProps = {},
    title,
    color,
    align,
    links,
    // expandable,
    headingVariant,
    headingId,
    ...restProps
  } = omit(["expandable"], props);

  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();

  let MaybeSection = title ? Section : Fragment;

  const [defaltHeadingId] = useState(
    () => `module-heading-${generateUniqueId()}`,
  );
  headingId = headingId || defaltHeadingId;
  return (
    <Box color={color || "transparent"} padding={[0, 0]} {...restProps}>
      {title && (
        <Heading
          variant={headingVariant}
          marginAfter
          id={headingId}
          align={align}
        >
          {title}
        </Heading>
      )}

      <TypographyBlock align={align}>
        <MaybeSection>{description}</MaybeSection>
      </TypographyBlock>
      <MaybeSection>
        {!!links?.length && (
          <InlineList
            css={css`
              margin-top: ${theme.getLength(5)};
              justify-content: ${align};
            `}
          >
            {links.map(({ link, variant, icon }, index) => (
              <Button
                key={index}
                variant={variant ?? "primary"}
                {...buttonProps}
                {...link}
              >
                {icon && <Icon name={icon} />}
                {link.title}
              </Button>
            ))}
          </InlineList>
        )}
      </MaybeSection>
    </Box>
  );
}
