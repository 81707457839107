/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Button, createTheme, withDefaultProps, Icon } from "@wsui/base";
import { ExpandableTextModule } from "@wsui/municipio";

import BrochuresBillboardModule from "../BrochuresBillboardModule.jsx";
import DefaultHit from "../DefaultHit.jsx";
import DefaultTextModule from "../DefaultTextModule.jsx";
import HeroBillboardModule from "../HeroBillboardModule.jsx";
import ImageBillboardModule from "../ImageBillboardModule.jsx";
import PostPostCard from "../PostPostCard.jsx";
import PostsModuleExpandableListLayout from "../PostsModuleExpandableListLayout.jsx";
import PostsModuleGridLayout from "../PostsModuleGridLayout.jsx";
import PostsModuleTeaserItem from "../PostsModuleTeaserItem.jsx";
import ProductPostCard from "../ProductPostCard.jsx";
import QuoteBillboardModule from "../QuoteBillboardModule.jsx";

import "./index.css";

export default createTheme({
  colors: {
    paxxo: "#002B5C",
    black: "#000000",
    white: "#ffffff",
    green: "#75C043",
    blue: { main: "#00AAE7", light: "#EFF4F6", breeze: "#D5ECF5" },
    yellow: { main: "#FCAF17", light: "#FFF9EE" },
    red: "#A21233",
    orange: "#F58025",

    gray: "blue.light",
    primary: "paxxo",
    alert: "yellow",
    error: "alert",
    warning: "alert",
    success: "gray",
    info: "gray",
    border: "gray.100",
  },
  textColors: ["black", "white"],
  linkColors: ["black", "white"],
  typography: {
    fonts: {
      CalibriBold: {
        src: {
          normal: 'url("/fonts/CalibriBold.TTF") format("truetype")',
        },
      },
      Calibri: {
        src: {
          italic: 'url("/fonts/CalibriItalic.TTF") format("truetype")',
          normal: 'url("/fonts/CalibriRegular.TTF") format("truetype")',
        },
      },
      HelveticaNeue: {
        src: {
          italic:
            'url("/fonts/HELVETICANEUELTPRO-Bold.OTF") format("opentype")',

          normal:
            'url("/fonts/HELVETICANEUE-LT-55-ROMAN-REGULAR.TTF") format("truetype")',
        },
      },
      HelveticaNeueBold: {
        src: {
          normal:
            'url("/fonts/HELVETICANEUELTPRO-Bold.OTF") format("opentype")',
        },
      },
    },
    variants: {
      h1: {
        fontFamily: "HelveticaNeueBold",
        fontSize: [6, 8],
        lineHeight: [7.5, 10.5],
        // marginBlockStart: [9.25, 15],
        // marginBlockEnd: [9.25 / 2, 15 / 2],
      },
      h2: {
        fontFamily: "HelveticaNeueBold",
        fontSize: [5, 6],
        lineHeight: [6.5, 8.5],
        // marginBlockStart: [9.25, 12],
        // marginBlockEnd: [9.25 / 2, 12 / 2],
      },
      h3: {
        fontFamily: "HelveticaNeueBold",
        fontSize: [4.5, 5.25],
        lineHeight: [6.5, 7.75],
        // marginBlockStart: [6.5, 6.5],
        // marginBlockEnd: [6.5 / 2, 6.5 / 2],
      },

      preamble: {
        fontFamily: "HelveticaNeue",
        fontSize: [4.5, 5.25],
        lineHeight: [6.5, 7.75],
      },
      body: {
        fontFamily: "Calibri",
        fontSize: [4, 4.75],
        lineHeight: [6, 6.5],
      },
      bodyBold: {
        fontFamily: "CalibriBold",
        fontSize: [4, 4.75],
        lineHeight: [6, 6.5],
      },
      quote: {
        fontFamily: "HelveticaNeue",
        fontSize: [5, 6],
        lineHeight: [6.5, 8.5],
      },
      description: {
        fontFamily: "HelveticaNeue",
        fontSize: [4, 4],
        lineHeight: [6, 6],
      },
      descriptionBold: {
        fontFamily: "CalibriBold",
        fontSize: [4, 4],
        lineHeight: [6, 6],
      },
      formLabel: {
        fontFamily: "Calibri",
        fontSize: [4, 4],
        lineHeight: [6, 6],
      },
    },
  },
  components: {
    AlgoliaHitController: {
      defaultProps: {
        components: {
          DefaultHit,
        },
      },
    },
    AlgoliaDefaultHits: {
      defaultProps: {
        divider: false,
      },
    },
    Breadcrumbs: {
      defaultProps: {
        description: <Icon name="landscape" size={8} />,
        fontSize: [4, 4.75],
        style: {
          fontFamily: "Calibri",
          "--icon-color": "paxxo",
          display: "flex",
          alignItems: "center",
          "& > ol > li:last-child": {
            fontFamily: "CalibriBold",
          },
        },
      },
    },
    Button: {
      defaultProps: {
        style: {
          padding: "10px 26px",
        },
      },
      variants: {
        primary: {
          color: "yellow",
          style: {
            color: "black",
          },
        },
        secondary: {
          color: "paxxo",
          style: {
            color: "white",
          },
        },
        tertiary: {
          color: "white",
          style: {
            color: "paxxo",
          },
        },
        submit: {
          color: "yellow",
          style: {
            fontWeight: 600,
          },
        },
      },
    },
    CardTitle: {
      defaultProps: {
        color: "paxxo",
      },
    },
    CookieDialog: {
      defaultProps: {
        color: "blue.light",
      },
    },
    Footer: {
      defaultProps: {
        columnColor: "#FFFFFF0D",
      },
    },
    FormField: {
      defaultProps: {
        inputProps: {
          style: css`
            border: 1px solid #d0d0d0;
            border-radius: 5px;
          `,
        },
      },
    },
    FormSelectField: {
      defaultProps: {
        inputProps: {
          css: css`
            color: #002b5c;
          `,
        },
        css: css`
          width: 35%;
          @media (max-width: 640px) {
            width: 100%;
          }
        `,
      },
    },
    Select: {
      defaultProps: {
        styles: {
          placeholder: (provided) => ({
            ...provided,
            color: "white",
          }),
          control: (base) => ({
            ...base,
            backgroundColor: "#002B5C",
            borderRadius: "5px",
            padding: "6px",
            fontSize: "1.2rem",
          }),
          singleValue: (base) => ({
            ...base,
            color: "white",
          }),
        },
        components: {
          ClearIndicator: null,
          IndicatorSeparator: null,
        },
      },
    },
    Header: {
      defaultProps: {
        color: "white",
      },
    },

    HeaderHelpMenu: {
      defaultProps: {
        style: {
          backgroundColor: "blue.light",
          fontWeight: 600,
          color: "paxxo",
          padding: "1rem 0rem",
          fontSize: "16px",
        },
      },
    },
    HeaderMainMenu: {
      defaultProps: {
        fontSize: "21px",
        padding: "1rem",
        components: {
          Button: withDefaultProps(Button, {
            variant: "primary",
          }),
        },
      },
    },
    MixedGridLayout: {
      defaultProps: {
        columnMinWidth: 300,
      },
    },
    ModularityArea: {
      defaultProps: {
        gap: [8.75, 14.5],
      },
    },
    MunicipioBillboardModuleController: {
      defaultProps: {
        components: {
          HeroBillboardModule,
          QuoteBillboardModule,
          BrochuresBillboardModule,
          ImageBillboardModule,
        },
      },
    },
    MunicipioTextModuleController: {
      defaultProps: {
        componentMapping: ({ expandable }) => {
          if (expandable) return ExpandableTextModule;
          return DefaultTextModule;
        },
      },
    },
    MunicipioPostsModuleExpandableListLayout: {
      defaultProps: {
        style: {
          padding: 5,
          borderRadius: 2,
        },
      },
    },
    MunicipioHeroBillboardModule: {
      defaultProps: {
        contentElementMapping: { p: "body" },
      },
      transformProps: (props) => {
        if (props.format === "hero") {
          delete props.color;
        }
      },
    },
    MunicipioFeatureBillboardModule: {
      defaultProps: {
        contentElementMapping: { p: "body" },
      },
    },
    MunicipioPostsModuleListLayout: {
      defaultProps: {
        divider: false,
      },
    },
    MunicipioPostsModule: {
      defaultProps: {
        displayModeMapping: {
          "expandable-list": PostsModuleExpandableListLayout,
          grid: PostsModuleGridLayout,
        },
      },
    },
    MunicipioPostsModuleItem: {
      defaultProps: {
        variantMapping: {
          teaser: PostsModuleTeaserItem,
        },
      },
    },
    PostCardController: {
      defaultProps: {
        componentMapping: {
          product: ProductPostCard,
          post: PostPostCard,
        },
      },
    },
    TreeMenu: {
      defaultProps: {
        collapseIcon: "minus",
        expandIcon: "plus",
      },
    },
    TreeMenuItem: {
      defaultProps: {
        hoverColor: "blue.light",
        color: "paxxo",
      },
    },
    TreeMenuItemHeader: {
      defaultProps: {
        activeColor: "paxxo",
      },
    },
    TreeMenuLink: {
      defaultProps: {
        fontWeight: 600,
        fontSize: "18px",
        padding: "1.25rem",
      },
    },
    TreeMenuToggleButton: {
      defaultProps: {
        offset: "1.25rem",
      },
    },
    TypographyBlock: {
      defaultProps: {
        elementMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          p: "body",
          blockquote: "quote",
          "p > b": "bodyBold",
          "p > strong": "bodyBold",
        },
      },
    },

    SlidePanelAction: {
      defaultProps: {
        color: "primary",
      },
    },
  },
});
