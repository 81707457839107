/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useThemeProps } from "@wsui/base";
import { FeatureBillboardModule } from "@wsui/municipio";
import { omit } from "lodash/fp";

HeroBillboardModule.wsuiConfig = {
  hasCaption: false,
};

export default function HeroBillboardModule(props) {
  props = useThemeProps({
    props,
    name: "MunicipioHeroBillboardModule",
  });
  props = {
    ...props,
    titleVariant: props.titleVariant ?? "h1",
  };
  return <FeatureBillboardModule {...omit(["titleIcon"], props)} />;
}
