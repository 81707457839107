/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import { useThemeProps, Box, Clickable, Typography } from "@wsui/base";

import Portal from "./Portal.jsx";

export default function Brochure(props) {
  props = useThemeProps({
    props,
    name: "Brochure",
  });
  let {
    setBrochureModalIsOpen,
    backgroundColor = "white",
    title: modalTitle,
    description: modalDescription,
    brochures,
  } = props;

  const theme = useTheme();

  return (
    <Portal setIsOpen={setBrochureModalIsOpen}>
      <div
        css={css`
          padding: 3rem 5rem 7rem 5rem;
          background-color: ${backgroundColor};

          @media (min-width: 800px) {
            border-radius: ${theme.getLength(5)};
          }
        `}
      >
        <Typography
          variant="h1"
          css={css`
            margin-bottom: 0.5rem;
          `}
        >
          {modalTitle}
        </Typography>
        <Typography variant="description">{modalDescription}</Typography>

        <div
          css={css`
            padding-top: 3rem;
            display: grid;
            row-gap: ${theme.getLength(8)};
            column-gap: ${theme.getLength(8)};
            grid-template-columns: repeat(1, minmax(min(100%, 40px), 1fr));

            @media (min-width: 640px) {
              grid-template-columns: repeat(2, minmax(min(100%, 40px), 1fr));
            }

            @media (min-width: 900px) {
              grid-template-columns: ${
                brochures?.length > 2
                  ? "repeat(3, minmax(min(100%, 40px), 1fr))"
                  : brochures?.length === 2
                  ? "repeat(2, minmax(min(100%, 40px), 1fr))"
                  : "repeat(1, minmax(min(100%, 40px), 1fr))"
              };

          `}
        >
          {brochures?.map(({ title, description, file }) => {
            return (
              <Clickable
                href={file?.mediaItemUrl}
                download={title}
                key={title}
                css={css`
                  text-decoration: none;
                `}
              >
                <Box
                  padding="none"
                  height={198}
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.getColor("yellow")};
                    border-radius: 6px;

                    @media (min-width: 640px) {
                      width: 258px;
                    }
                  `}
                >
                  <Typography
                    variant="h3"
                    css={css`
                      margin-bottom: 0.5rem;
                    `}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="description"
                    css={css`
                      padding: 0 1rem;
                      text-align: center;
                    `}
                  >
                    {description}
                  </Typography>
                </Box>
              </Clickable>
            );
          })}
        </div>
      </div>
    </Portal>
  );
}
