/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Clickable } from "@wsui/base";
import { graphql, useStaticQuery } from "gatsby";

import logo from "../../../../images/paxxo-logo.svg";

export default function HeaderLogo({ link, ...restProps }) {
  link = typeof link === "string" ? { href: link } : link;
  let title = useStaticQuery(graphql`
    query HeaderLogoQuery_Nsr {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)?.site?.siteMetadata?.title;
  return (
    <div {...restProps}>
      <div>
        <Clickable
          {...link}
          css={css`
            color: inherit;
            text-decoration: inherit;
          `}
        >
          <img
            src={logo}
            alt={title}
            css={css`
              display: block;

              @media (max-width: 640px) {
                width: 80%;
              }
            `}
          />
        </Clickable>
      </div>
    </div>
  );
}
