/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor.js";
import {
  Alert,
  MaybeFragment,
  PageGrid,
  PageGridItem,
  useThemeProps,
  Typography,
  Button,
  handleComponentsProp,
} from "@wsui/base";
import { graphql, useStaticQuery } from "gatsby";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import longopacLogo from "../../../../images/longopac.svg";
import paxxoLogo from "../../../../images/paxxo-logo.svg";

import AlertPopup from "./AlertPopup.jsx";

function DefaultReadMore({
  ownerState: {
    alertOwnerState: { color: alertColor },
  },
  children,
  color = "paxxo",
  ...restProps
}) {
  return (
    <Button
      color={[alertColor, color]}
      style={{
        paddingBlock: 3,
        paddingInline: 3,
        marginBlock: 0,
        borderRadius: 1,
      }}
      variant={null}
      {...restProps}
    >
      {children}
    </Button>
  );
}

export default function AlertBanner(props) {
  props = useThemeProps({ props, name: "AlertBanner" });
  const { t } = useTranslation();
  let {
    components,
    readMoreText = t(["alertBanner.readMore", "readMore", "Read more"]),
    ...restProps
  } = props;

  let { ReadMore } = handleComponentsProp(components, {
    ReadMore: DefaultReadMore,
  });
  const { getPlainTextExcerpt } = useHTMLProcessor();
  const [dismissedBannerIds, setDismissedBannerIds] = useState([]);

  let data = useStaticQuery(graphql`
    query LocalAlertQuery {
      wp {
        alerts(first: 1000) {
          nodes {
            id
            ...WP_AlertForBanner
          }
        }
      }
    }
  `);
  let alerts = data.wp?.alerts?.nodes;

  if (alerts?.length === 0) {
    return null;
  }

  function dismissAlertBanner(id) {
    setDismissedBannerIds((dismissedBannerIds) => [...dismissedBannerIds, id]);
  }

  function checkForDismissedBanner(id) {
    return dismissedBannerIds.find((item) => item === id);
  }

  const ownerState = {
    ...restProps,
  };

  const logos = {
    longopac: { alt: "Longopac@", src: longopacLogo },
    paxxo: { alt: "Paxxo", src: paxxoLogo },
  };
  return (
    <MaybeFragment>
      {alerts
        .filter(({ id }) => !checkForDismissedBanner(id))
        .map(
          ({
            id,
            excerpt,
            content,
            hasPageContent,
            uri,
            alertSettings: { readMorePage, display },
            logo: { showLogo },
            title,
          }) => {
            let plainTextExcerpt = getPlainTextExcerpt({ excerpt, content });
            let readMoreProps = { title: readMoreText };
            if (readMorePage) {
              ({ url: uri, ...readMoreProps } = readMorePage);
              hasPageContent = true;
            }
            if (!plainTextExcerpt) return null;

            const displayAsPopup = display === "popup";

            return displayAsPopup ? (
              <AlertPopup
                id={id}
                dismissAlertBanner={dismissAlertBanner}
                readMoreProps={readMoreProps}
                plainTextExcerpt={plainTextExcerpt}
                uri={uri}
                ownerState={ownerState}
                title={title}
                hasPageContent={hasPageContent}
                {...restProps}
              />
            ) : (
              <Alert
                key={id}
                borderRadius={0}
                components={{
                  Inner: ({ children, ...restProps }) => (
                    <PageGrid
                      css={css`
                        @media (max-width: 640px) {
                          padding-inline: 18px 5px;
                        }
                      `}
                      {...restProps}
                    >
                      <PageGridItem colspan={12}>{children}</PageGridItem>
                    </PageGrid>
                  ),
                }}
                style={{
                  paddingInline: 0,
                }}
                {...restProps}
              >
                {(alertOwnerState) => (
                  <div
                    css={css`
                      display: flex;
                      gap: 1rem;
                      flex-wrap: wrap;
                      justify-content: start;
                      flex-direction: column;
                      align-items: center;

                      @media (min-width: 640px) {
                        flex-direction: row;

                        & > :last-child {
                          margin-left: auto;
                        }
                      }

                      @media (max-width: 640px) {
                        align-items: stretch;
                      }
                    `}
                  >
                    {showLogo && logos[showLogo] && (
                      <img
                        src={logos[showLogo]?.src}
                        alt={logos[showLogo]?.alt}
                        css={css`
                          height: 20px;
                          align-self: self-start;

                          @media (min-width: 640px) {
                            align-self: unset;
                          }
                        `}
                      />
                    )}
                    <div css={css``}>
                      <Typography variant="description">
                        {plainTextExcerpt}
                      </Typography>
                    </div>
                    {!!(hasPageContent && uri) && (
                      <ReadMore
                        key="button"
                        ownerState={{
                          ...ownerState,
                          alertOwnerState,
                        }}
                        uri={uri}
                        variant="secondary"
                        label={title}
                        css={css`
                          white-space: nowrap;
                        `}
                        {...readMoreProps}
                      />
                    )}
                  </div>
                )}
              </Alert>
            );
          },
        )}
    </MaybeFragment>
  );
}
