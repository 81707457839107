import { useStaticQuery, graphql } from "gatsby";

export default function useProductsInTreeMenu() {
  const data = useStaticQuery(graphql`
    query ProductPagesForTreeMenu {
      wp {
        products {
          nodes {
            slug
            title
          }
        }
      }
    }
  `);
  return data?.wp?.products?.nodes || [];
}
