import ModuleWrapper from "@municipio/gatsby-theme-basic/src/wsui/components/ModuleWrapper.jsx";
import { useThemeProps } from "@wsui/base";
import React from "react";

export default function IframeModule(props) {
  props = useThemeProps({ name: "MunicipioIframeModule", props });
  let {
    title,
    module: {
      modIframeOptions: { iframeHeight, iframeUrl },
    },
    ...restProps
  } = props;
  if (!iframeUrl) return null;
  return (
    <ModuleWrapper title={title} {...restProps}>
      <iframe
        src={iframeUrl}
        height={iframeHeight}
        width="100%"
        frameBorder={0}
      />
    </ModuleWrapper>
  );
}
