/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useLocation } from "@gatsbyjs/reach-router";
import DefaultHamburgerMenu from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/HeaderHamburgerMenu.jsx";
import DefaultLogo from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/HeaderLogo.jsx";
import {
  Clickable,
  Icon,
  PageGrid,
  PageGridItem,
  Link,
  handleComponentsProp,
  useThemeProps,
  usePrevious,
  Button,
} from "@wsui/base";
import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import HeaderHelpMenu from "../../../../HeaderHelpMenu.jsx";
import HeaderSearchField from "../../../../wsui/components/HeaderSearchField.jsx";

import HeaderMainMenu from "./HeaderMainMenu.jsx";
import SubMenu from "./SubMenu.jsx";

const DefaultHamburgerMenuToggle = styled(Clickable)``;

const SkipToMainLink = styled(Button)`
  position: absolute;
  left: 50%;
  transform: translateY(-550%);
  transition: transform 0.3s;
  z-index: 1;
  &:focus {
    transform: translateY(0%);
  }
`;

export default function Header(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  props = useThemeProps({
    props,
    name: "Header",
  });

  let {
    color = "primary",
    components,
    disableHamburgerMenu = false,
    ...restProps
  } = props;

  let { HamburgerMenu, HamburgerMenuToggle, Logo, MainMenu } =
    handleComponentsProp(components, {
      HamburgerMenu: DefaultHamburgerMenu,
      HamburgerMenuToggle: DefaultHamburgerMenuToggle,
      Logo: DefaultLogo,
      MainMenu: HeaderMainMenu,
      HeaderHelpMenu,
    });

  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [activeSubMenu, setActiveSubMenu] = useState(undefined);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = hamburgerOpen ? "hidden" : "";
  }, [hamburgerOpen]);

  useEffect(() => {
    if (location !== prevLocation) {
      setHamburgerOpen(false);
    }
  }, [location, prevLocation]);

  return (
    <Fragment>
      <header {...restProps}>
        <SkipToMainLink variant="primary" href="#main">
          Gå direkt till innehåll
        </SkipToMainLink>
        {HeaderHelpMenu && (
          <HeaderHelpMenu
            css={css`
              @media (max-width: 640px) {
                display: none;
              }
            `}
          />
        )}
        <PageGrid
          css={css`
            background-color: ${theme.getColor(color)};
            color: ${theme.getColor([color, "text"])};
          `}
        >
          <PageGridItem colspan={12}>
            <div
              css={css`
                display: grid;
                grid-template-areas: "logo main-menu search flyout";
                padding-block: 1.5rem;
                gap: ${theme.getLength([4, 8])};
                align-items: center;
                grid-template-columns: max-content minmax(0, 1fr) max-content;
              `}
            >
              <Logo
                link="/"
                css={css`
                  grid-area: logo;
                `}
              />
              <Link
                uri="/sok"
                aria-label="Sök"
                css={css`
                  grid-area: search;
                  @media (min-width: 841px) {
                    display: none;
                  }
                `}
              >
                <Icon
                  name="search"
                  alt="Sök"
                  size="1.7rem"
                  css={css`
                    color: ${theme.getColor("paxxo")};
                  `}
                />
              </Link>
              <MainMenu
                css={css`
                  align-self: stretch;
                  display: grid;
                  align-content: center;
                  grid-template-columns: minmax(0, 1fr);
                `}
                setActiveSubMenu={setActiveSubMenu}
              />
              <HeaderSearchField
                css={css`
                  grid-area: search;

                  @media (max-width: 840px) {
                    display: none;
                  }
                `}
              />
              {!disableHamburgerMenu && (
                <HamburgerMenuToggle
                  onClick={() => {
                    setHamburgerOpen(true);
                  }}
                  aria-label={t("menu")}
                  aria-expanded={String(hamburgerOpen)}
                  aria-controls={"header-hamburger-menu"}
                  css={css`
                    grid-area: flyout;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                    padding: 0;
                  `}
                >
                  <Icon
                    name="menu"
                    size="2.5rem"
                    color={theme.getColor("paxxo")}
                  />
                </HamburgerMenuToggle>
              )}
            </div>
          </PageGridItem>
        </PageGrid>

        <SubMenu
          activeSubMenu={activeSubMenu}
          onMouseLeave={() => setActiveSubMenu(undefined)}
        />
        {!disableHamburgerMenu && (
          <HamburgerMenu
            open={hamburgerOpen}
            onClose={() => setHamburgerOpen(false)}
            id={"header-hamburger-menu"}
          />
        )}
      </header>
    </Fragment>
  );
}
