import { css } from "@emotion/react";
import {
  TeaserCard,
  CardContent,
  CardTitle,
  CardMeta,
  Time,
  Button,
  Typography,
  useThemeProps,
  Excerpt,
  handleComponentsProp,
} from "@wsui/base";
import { postsModuleContext } from "@wsui/municipio";
import { pickAll } from "lodash/fp";
import React, { Fragment, useContext } from "react";

const DefaultExcerpt = Excerpt;
const DefaultButton = Button;

export default function PostsModuleTeaserItem(props) {
  props = useThemeProps({ props, name: "MunicipioPostsModuleTeaserItem" });
  let {
    item,
    visibleFields,
    dateFormat = {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    components = {},
    ...restProps
  } = props;

  let { Button, Excerpt } = handleComponentsProp(components, {
    Button: DefaultButton,
    Excerpt: DefaultExcerpt,
  });

  let link = item.link;

  item = pickAll(visibleFields, item);

  const { itemColor } = useContext(postsModuleContext);
  return (
    <Fragment>
      <TeaserCard
        title={item.title}
        color={itemColor}
        link={link}
        image={item.image}
        spacing={4}
        css={css`
          height: 100%;
        `}
        {...restProps}
      >
        <CardContent
          css={css`
            justify-content: left;
            grid-template-columns: 1fr;
          `}
        >
          <CardTitle variant="h4" />
          {item.date && (
            <CardMeta>
              <Time date={item.date} format={dateFormat} />
            </CardMeta>
          )}
          {item.description ? (
            <Excerpt>
              <Typography variant="description">{item.description}</Typography>
            </Excerpt>
          ) : null}
        </CardContent>
        {link ? (
          <div
            css={css`
              padding: 2em;
            `}
          >
            <Button {...link} title="Visa mer" variant="primary" />
          </div>
        ) : null}
      </TeaserCard>
    </Fragment>
  );
}
