/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import {
  Icon,
  useThemeProps,
  TypographyBlock,
  withDefaultProps,
  Accordion,
  Box,
  Heading,
  Section,
  backgroundColorContext,
  generateUniqueId,
  InlineList,
  Button,
  DetailsBox,
} from "@wsui/base";
import { PostsModuleArchiveLink, postsModuleContext } from "@wsui/municipio";
import { omit } from "lodash/fp";
import { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

/**
 * Uses item title and content instead of relying on visibleFields
 */

const StaticBox = ({ summary, children, ...restProps }) => {
  return (
    <Box {...restProps}>
      {summary}
      {children}
    </Box>
  );
};

export default function PostsModuleExpandableListLayout(props) {
  props = useThemeProps({
    props,
    name: "MunicipioPostsModuleExpandableListLayout",
  });
  let {
    title,
    titleIcon,
    titleIconProps = { size: 8 },
    description,
    items,
    spacing = 4,
    headingVariant,
    archiveLink,
    archiveLinkProps,
    headingId,
    toggleIconProps,
    expandable,
    color,
    ...restProps
  } = omit(["gridMaxColumns"], props);

  const { t } = useTranslation();
  const theme = useTheme();

  let ownerState = useContext(postsModuleContext);
  let { itemColor, autoItemColor } = ownerState;

  let containerBackgroundColor = useContext(backgroundColorContext);
  const isBackgroundWhite = containerBackgroundColor === "#ffffff";
  if (itemColor === "auto") {
    itemColor = isBackgroundWhite ? autoItemColor : "white";
  }

  let Wrapper = expandable ? DetailsBox : StaticBox;

  const [defaltHeadingId] = useState(
    () => `module-heading-${generateUniqueId()}`,
  );
  headingId = headingId || defaltHeadingId;
  return (
    <div>
      <Wrapper
        color={color || "transparent"}
        {...restProps}
        summary={
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr max-content;
              align-items: start;
            `}
          >
            {title && (
              <Heading
                variant={headingVariant}
                marginAfter={!expandable}
                id={headingId}
                css={css`
                  display: flex;
                  gap: 0.5em;
                  align-items: start;
                `}
              >
                {titleIcon && (
                  <Icon
                    name={titleIcon}
                    css={css`
                      align-self: center;
                      flex: none;
                    `}
                    {...titleIconProps}
                  />
                )}
                {title}
              </Heading>
            )}

            {!!(archiveLink && title) && (
              <PostsModuleArchiveLink {...archiveLink} {...archiveLinkProps} />
            )}
          </div>
        }
        components={{
          Summary: withDefaultProps(Heading, {
            style: { maxWidth: "none" },
            variant: headingVariant,
            id: headingId,
          }),
          SummaryToggle: {
            style: {
              alignItems: "start",
              padding: 6,
            },
          },
          SummaryToggleIcon: (props) => {
            const { isExpanded, expandLabel, collapseLabel } = props.ownerState;
            return (
              <Button
                variant="primary"
                css={css`
                  margin-inline-start: auto;
                  font-size: 19px;
                  min-width: 120px;
                `}
              >
                {isExpanded
                  ? collapseLabel
                    ? collapseLabel
                    : t("contentTypes.post.segmentButton.collapse")
                  : expandLabel
                  ? expandLabel
                  : t("contentTypes.post.segmentButton.expand")}
              </Button>
            );
          },
        }}
        {...restProps}
      >
        {description && (
          <TypographyBlock marginAfter>{description}</TypographyBlock>
        )}
        <Section>
          <Accordion
            spacing={spacing}
            marginAfter={!!(archiveLink && !title)}
            components={{
              Item: {
                as: Box,
                borderRadius: 2,
                color: itemColor,
                style: {
                  [[
                    "&.wsui-is-expanded",
                    "&:has(.wsui-details-summary-toggle.wsui-is-interactive:hover)",
                    "&:has(.wsui-details-summary-toggle.wsui-is-interactive:focus-visible)",
                  ]]: {
                    backgroundColor: itemColor
                      ? theme.getColor("yellow.light")
                      : undefined,
                  },
                  padding: 4,
                },
                components: {
                  Summary: withDefaultProps(Heading, {
                    style: { maxWidth: "none" },
                  }),
                  SummaryToggleIcon: {
                    size: 7.5,
                    ...toggleIconProps,
                  },
                },
              },
            }}
            items={items.map(({ content, link, ...item }) => ({
              ...item,
              content: (
                <Fragment>
                  <TypographyBlock>{content}</TypographyBlock>
                  {!!link?.title && (
                    <InlineList>
                      <Button {...link} variant="primary" />
                    </InlineList>
                  )}
                </Fragment>
              ),
            }))}
          />
          {!!(archiveLink && !title) && (
            <PostsModuleArchiveLink {...archiveLink} {...archiveLinkProps} />
          )}
        </Section>
      </Wrapper>
    </div>
  );
}
