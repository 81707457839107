/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus.js";
import {
  Clickable,
  PageGrid,
  PageGridItem,
  Stack,
  handleComponentsProp,
  useThemeProps,
  Typography,
  Link,
} from "@wsui/base";

import paxxoLogo from "../../../../images/paxxo-footer-logo.svg";
import FooterBlockController from "../../components/FooterBlockController.jsx";
import useFooterBlocks from "../../hooks/useFooterBlocks.js";

import DefaultLogo from "./FooterLogo.jsx";

export const DefaultLink = styled(Clickable)`
  text-decoration: none !important;
  color: inherit;
  font-family: Calibri;
  font-size: ${({ theme }) => theme.getLength(4)};
`;

const DefaultLegalLink = styled(DefaultLink)`
  font-family: Calibri;
  font-size: ${({ theme }) => theme.getLength([3.25, 3.75])};
`;

export default function Footer(inProps) {
  const theme = useTheme();
  const {
    color = "primary",
    columnColor,
    spacing,
    gap = [7.5, 15],
    blocksMargin = [4, 12],
    legalMenu = "LEGAL",
    components,
    ...restProps
  } = useThemeProps({
    props: inProps,
    name: "Footer",
  });
  let { Logo } = handleComponentsProp(components, {
    LegalMenuLink: DefaultLegalLink,
    Logo: DefaultLogo,
  });

  const footer = useFooterBlocks();
  let legalMenuItems = useMenu(legalMenu)?.items;

  const { columns, footerLogoText } = footer;

  return (
    <footer
      css={css`
        background: ${theme.getColor(color)};
        color: ${theme.getColor([color, "text"])};
      `}
      {...restProps}
    >
      <PageGrid
        maxWidth={400}
        css={css`
          margin-top: 6rem;
        `}
      >
        <PageGridItem>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <Logo
              logo={paxxoLogo}
              alt="Paxxo"
              css={css`
                @media (max-width: 640px) {
                  padding: 0 10px;
                }
              `}
              imageStyle={{
                maxWidth: "100%",
                minWidth: 0,
                flexShrink: 1,
              }}
            />
            <Typography
              css={css`
                padding: 2rem 0 2rem 0;
                text-align: center;
                font-size: 35px;
                line-height: 48px;

                @media (max-width: 640px) {
                  font-size: 26px;
                  line-height: 40px;
                }
              `}
            >
              {footerLogoText}
            </Typography>
          </div>
        </PageGridItem>
      </PageGrid>
      <PageGrid
        spacing={spacing}
        rowGap={blocksMargin}
        gap={gap}
        css={css`
          padding-block: ${theme.getLength(blocksMargin)};
        `}
      >
        {columns.map(({ blocks, colspan }, columnIndex) => (
          <PageGridItem key={columnIndex} colspan={[colspan, 12]}>
            <Stack
              justifyContent="start"
              direction="column"
              css={css`
                padding: ${columnIndex > 0
                  ? "2rem"
                  : "2rem 3.25rem 2.25rem 2rem"};
                background-color: ${columnColor};
                min-height: ${columnIndex > 0 ? "270px" : "auto"};
                border-radius: 6px;
              `}
            >
              {blocks.map((block, blockIndex) => (
                <FooterBlockController
                  key={blockIndex}
                  block={block}
                  headingVariant="bodyBold"
                />
              ))}
            </Stack>
          </PageGridItem>
        ))}
      </PageGrid>
      <PageGrid
        maxWidth="none"
        css={css`
          background-color: white;
        `}
      >
        <nav>
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-decoration: none;
              list-style-type: none;
              padding: 0;
              color: black;

              @media (min-width: 640px) {
                flex-direction: row;
                justify-content: center;
                list-style-type: unset;
              }
            `}
          >
            <li
              css={css`
                list-style-type: none;
              `}
            >
              <Typography variant="description">Copyright © 2024</Typography>
            </li>
            <li
              css={css`
                margin-left: 1.5em;
              `}
            >
              <Typography variant="description">PAXXO AB</Typography>
            </li>
            {legalMenuItems.map((item, index) => {
              return (
                <li
                  key={index}
                  css={css`
                    margin-left: 1.5em;
                    font-size: 19px;
                  `}
                >
                  <Link to={item.url}>{item.label}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </PageGrid>
    </footer>
  );
}
