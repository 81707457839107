/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useThemeProps } from "@wsui/base";

function getMachineDateString(date, precision) {
  const { [precision]: length } = {
    year: 4, // YYYY
    month: 7, // YYYY-MM
    day: 10, // YYYY-MM-DD
    hour: 13, // YYYY-MM-DD HH
    minute: 16, // YYYY-MM-DD HH:MM
    second: 19, // YYYY-MM-DD HH:MM:SS
  };
  return date.toISOString().substr(0, length);
}

export default function PostTime(props) {
  const theme = useTheme();
  props = useThemeProps({ props, name: "PostTime" });
  let { date, locale, ...restProps } = props;

  date = new Date(date);

  const machineDate = getMachineDateString(date, "second");

  return (
    <time
      dateTime={machineDate}
      css={css`
        display: flex;
        flex-flow: column;
        text-align: center;
        width: 52px;
        border-radius: 6px;
        border: 1px solid ${theme.getColor("primary")};
        color: ${theme.getColor("primary")};
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.02em;
        overflow: hidden;
        background-color: white;
      `}
      {...restProps}
    >
      <span
        css={css`
          font-size: 10px;
          color: white;
          background-color: ${theme.getColor("primary")};
          line-height: 18px;
        `}
      >
        {date.toLocaleString(locale, { month: "long" }).substr(0, 3)}
      </span>
      <span
        css={css`
          font-size: 25px;
          line-height: 34px;
        `}
      >
        {date.getDate().toString().padStart(2, "0")}
      </span>
    </time>
  );
}
