import { css, useTheme } from "@emotion/react";
import {
  Icon,
  Heading,
  useThemeProps,
  TypographyBlock,
  Section,
  generateUniqueId,
} from "@wsui/base";
import {
  PostsModuleArchiveLink,
  postsModuleContext,
  PostsModuleItem,
} from "@wsui/municipio";
import { omit } from "lodash/fp";
import React, { useContext, useState } from "react";

export default function PostsModuleGridLayout(props) {
  props = useThemeProps({ props, name: "MunicipioPostsModuleGridLayout" });
  let {
    title,
    titleIcon,
    titleIconProps = { size: 8 },
    description,
    items,
    headingVariant,
    // archiveLink,
    gridMaxColumns,
    archiveLink,
    archiveLinkProps,
    headingId,
    ...restProps
  } = omit([], props);
  const theme = useTheme();

  let ownerState = useContext(postsModuleContext);
  let { itemVariant, visibleFields } = ownerState;

  //   itemColspan = gridMaxColumns
  //     ? [12 / gridMaxColumns, 12 / gridMaxColumns, 12]
  //     : itemColspan;

  let mediaColspan = gridMaxColumns === 1 ? 6 : undefined;

  const [defaltHeadingId] = useState(
    () => `module-heading-${generateUniqueId()}`,
  );
  headingId = headingId || defaltHeadingId;

  const sortedItems = items.sort(
    (a, b) =>
      (a.productData?.sortOrder ?? Number.MAX_SAFE_INTEGER) -
      (b.productData?.sortOrder ?? Number.MAX_SAFE_INTEGER),
  );
  return (
    <div
      css={css`
        height: 100%;
      `}
      {...restProps}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr max-content;
          align-items: start;
        `}
      >
        {title && (
          <Heading
            variant={headingVariant}
            marginAfter
            id={headingId}
            css={css`
              display: flex;
              gap: 0.5em;
              align-items: start;
            `}
          >
            {titleIcon && (
              <Icon
                name={titleIcon}
                css={css`
                  align-self: center;
                  flex: none;
                `}
                {...titleIconProps}
              />
            )}
            {title}
          </Heading>
        )}
        {archiveLink && (
          <PostsModuleArchiveLink {...archiveLink} {...archiveLinkProps} />
        )}
      </div>
      {description && (
        <TypographyBlock marginAfter>{description}</TypographyBlock>
      )}
      <Section>
        <div
          css={css`
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(
              ${gridMaxColumns},
              minmax(min(100%, 40px), 1fr)
            );
            row-gap: ${theme.getLength(6)};
            column-gap: ${theme.getLength(6)};

            @media (max-width: 840px) {
              grid-template-columns: repeat(2, minmax(min(100%, 40px), 1fr));
            }
            @media (max-width: 640px) {
              grid-template-columns: repeat(1, minmax(min(100%, 40px), 1fr));
            }
          `}
        >
          {sortedItems.map((item, index) => (
            <div key={index}>
              <PostsModuleItem
                item={item}
                visibleFields={visibleFields}
                variant={itemVariant}
                mirrored={gridMaxColumns === 1 && index % 2 === 1}
                mediaColspan={mediaColspan}
                css={css`
                  height: 100%;
                `}
              />
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
}
