import { css, useTheme } from "@emotion/react";
import { ClickableIcon } from "@wsui/base";
import React from "react";
import ReactDOM from "react-dom";

export default function Portal({ children, setIsOpen }) {
  const theme = useTheme();
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  if (!hasMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      css={css`
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        overflow: scroll;
      `}
      onClick={() => setIsOpen(false)}
    >
      <div
        css={css`
          @media (min-width: 640px) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
        onClick={(e) => e.stopPropagation()}
      >
        <ClickableIcon
          name="close"
          size={6}
          aria-label={"Close"}
          css={css`
            color: ${theme.getColor("paxxo")};
            background-color: white;
            position: absolute;
            top: 2%;
            right: 5%;
            z-index: 1;

            @media (min-width: 640px) {
              top: 6%;
              right: 12%;
            }

            @media (min-width: 900px) {
              top: 10%;
              right: 8%;
            }
          `}
          onClick={() => setIsOpen(false)}
        />
        {children}
      </div>
    </div>,
    document.querySelector(`#portal`),
  );
}
