/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useThemeProps, Icon } from "@wsui/base";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

HeaderSearchField.propTypes = {
  menuName: PropTypes.string,
  color: PropTypes.string,
  resultType: PropTypes.string,
};

export default function HeaderSearchField(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  props = useThemeProps({ name: "HeaderSearchField", props });
  let { resultType, ...restProps } = props;

  return (
    <form
      method="GET"
      action={resultType ? `/${resultType}` : "/sok"}
      css={css``}
      {...restProps}
    >
      <label
        htmlFor="site-search"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Sök
      </label>
      <div
        css={css`
          position: relative;
          display: grid;
          align-items: center;
          & > * {
            grid-column: 1;
            grid-row: 1;
            font-family: Calibri;
          }
        `}
      >
        <Icon
          name="search"
          size="1.2rem"
          css={css`
            pointer-events: none;
            margin-left: 1.25rem;
            color: ${theme.getColor("paxxo")};
          `}
        />
        <input
          id="site-search"
          type="search"
          name="query"
          placeholder={t("siteSearchTitle")}
          autoComplete="off"
          css={css`
            padding: 1rem 2rem 1rem 3.7rem;
            background-color: ${theme.getColor("white")};
            width: 100%;
            border: none;
            border-radius: 5px;
            font-size: ${theme.getLength(4)};
            box-shadow: 0px 1px 6px 0px #00000029 inset;

            &::placeholder {
              color: ${theme.getColor("paxxo")};
            }
          `}
        />
      </div>
      <button
        type="submit"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Sök
      </button>
    </form>
  );
}
