/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import {
  useThemeProps,
  Button,
  Heading,
  TypographyBlock,
  Section,
  Card,
  CardContent,
  Stack,
  InlineList,
  Icon,
} from "@wsui/base";
import { omit } from "lodash/fp";
import { Fragment, useState } from "react";

import Brochure from "./Brochure.jsx";

BrochuresBillboardModule.wsuiConfig = {
  hasCaption: true,
};

export default function BrochuresBillboardModule(props) {
  props = useThemeProps({
    props,
    name: "MunicipioBrochuresBillboardModule",
  });
  let {
    content,
    title,
    titleVariant = "h1",
    horizontalContentMaxWidth = "100%",
    contentGap = 5,
    contentElementMapping = { p: "description" },
    cardContentProps = {},
    brochures,
    brochureTitle,
    buttonText = "Hämta broschyr",
  } = omit(["color", "align", "buttonMarginTop", "headingVariant"], props);

  let MaybeSection = title ? Section : Fragment;

  const [brochureModalIsOpen, setBrochureModalIsOpen] = useState(false);
  return (
    <div
      css={css`
        width: 55%;
        @media (min-width: 641px) {
          margin-top: 80px;
          margin-bottom: 80px;
        }
        @media (max-width: 640px) {
          width: 100%;
        }
      `}
    >
      <Card
        color="paxxo"
        css={css`
          padding-top: 30px;
        `}
        horizontalContentMaxWidth={horizontalContentMaxWidth}
      >
        <CardContent {...cardContentProps}>
          <Stack spacing={contentGap}>
            {!!title && <Heading variant={titleVariant}>{title}</Heading>}
            <MaybeSection>
              <TypographyBlock
                maxBlockMargin={contentGap}
                elementMapping={contentElementMapping}
              >
                {content}
              </TypographyBlock>
              <InlineList>
                {brochures?.length && (
                  <Button
                    onClick={() => {
                      setBrochureModalIsOpen(true);
                    }}
                    variant="primary"
                  >
                    <Icon name="landscape" size="5" />
                    {buttonText}
                  </Button>
                )}
              </InlineList>
            </MaybeSection>
          </Stack>
        </CardContent>
      </Card>

      {brochureModalIsOpen && (
        <Brochure
          brochures={brochures}
          title={brochureTitle}
          setBrochureModalIsOpen={setBrochureModalIsOpen}
        />
      )}
    </div>
  );
}
