/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import {
  Card,
  CardContent,
  CardTitle,
  Button,
  Typography,
  useThemeProps,
  handleComponentsProp,
  parseStyle,
} from "@wsui/base";
import { omit } from "lodash/fp";
import { Fragment } from "react";

const DefaultExcerpt = Typography;
const DefaultButton = Button;

export default function ProductPostCard(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "ProductPostCard" });
  props = useThemeProps({ props, name: "PostCard" });
  let {
    as: Component = Card,
    post,
    components = {},
    style,
    buttonMarginTop = 5,
    titleProps = { variant: "h4" },
    isFieldVisible,
    ctaVariant,
    ...restProps
  } = omit(["dateFormat", "metaProps", "visibleFields"], props);

  let { title, image, link, description, cta } = post;

  let { Button, Excerpt, Title } = handleComponentsProp(components, {
    Button: DefaultButton,
    Excerpt: DefaultExcerpt,
    Title: CardTitle,
  });

  return (
    <Component
      title={isFieldVisible("title") ? title : null}
      link={link}
      image={isFieldVisible("image") ? image : null}
      mediaAspectRatio={1 / 1}
      css={css`
        ${parseStyle(style, theme)}
      `}
      {...restProps}
    >
      <Fragment>
        <CardContent>
          <div
            css={css`
              display: flex;
              row-gap: inherit;
              align-items: center;
              column-gap: ${theme.getLength(5)};
            `}
          >
            <div
              css={css`
                display: grid;
                row-gap: inherit;
              `}
            >
              <Title {...titleProps} />
              {isFieldVisible("description") ? (
                <Excerpt variant="description">{description}</Excerpt>
              ) : null}
              {cta ? (
                <Button
                  css={css`
                    justify-self: start;
                    margin-block-start: ${theme.getLength(buttonMarginTop)};
                  `}
                  variant={ctaVariant}
                  {...cta}
                />
              ) : null}
            </div>
          </div>
        </CardContent>
      </Fragment>
    </Component>
  );
}
