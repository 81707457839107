/** @jsx jsx */
import { jsx, css } from "@emotion/react";

BrochuresImageModule.wsuiConfig = {
  hasCaption: true,
};

export default function BrochuresImageModule() {
  return (
    <div
      css={css`
        height: 470px;
      `}
    />
  );
}
