/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import {
  InlineList,
  Clickable,
  useThemeProps,
  handleComponentsProp,
  parseStyle,
} from "@wsui/base";
import { Fragment } from "react";

const HeaderMainMenuRoot = styled("div")``;

const DefaultLink = styled(Clickable)`
  text-decoration: inherit;
  color: inherit;
  font-weight: 600;
  &.wsui-is-interactive:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;
export default function HeaderMainMenu(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "HeaderMainMenu" });
  let {
    menu = "HEADER_TABS_MENU",
    padding,
    fontSize,
    components,
    style,
    setActiveSubMenu,
    ...restProps
  } = props;
  let { Link, List } = handleComponentsProp(components, {
    Link: DefaultLink,
    List: InlineList,
  });
  let items = useMenu(menu)?.items;

  if (!items?.length) return null;

  return (
    <Fragment>
      <HeaderMainMenuRoot
        css={css`
          ${parseStyle(style, theme)};
        `}
        {...restProps}
      >
        <List overflow="hidden" alignItems="center" spacing={2}>
          {items.map(({ url, label, target }, index) => {
            return (
              <Link
                url={url}
                target={target}
                key={index}
                css={css`
                  padding: ${padding};
                  font-size: ${fontSize};
                  color: ${theme.getColor("paxxo")};
                `}
                onMouseEnter={() => setActiveSubMenu(url)}
              >
                {label}
              </Link>
            );
          })}
        </List>
      </HeaderMainMenuRoot>
    </Fragment>
  );
}
