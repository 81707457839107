import { css, useTheme } from "@emotion/react";
import {
  TeaserCard,
  CardContent,
  CardTitle,
  useThemeProps,
  Excerpt,
  Button,
} from "@wsui/base";
import React from "react";
import { useTranslation } from "react-i18next";

export default function DefaultHit(props) {
  props = useThemeProps({ props, name: "AlgoliaDefaultHit" });
  props = useThemeProps({ props, name: "AlgoliaHit" });
  let {
    hit,
    horizontalContentMaxWidth = "100%",
    hideImage = true,
    // eslint-disable-next-line no-unused-vars
    hitsOwnerState,
    titleProps = {},
    ...restProps
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const { title, url, text, image } = hit;

  return (
    <TeaserCard
      link={{ url }}
      title={title}
      image={hideImage ? undefined : image || {}}
      mirrored
      mediaColspan={3}
      as="li"
      horizontalContentMaxWidth={horizontalContentMaxWidth}
      {...restProps}
    >
      <CardContent
        css={css`
          padding: 2rem 3rem 2rem 3.5rem;
          border-radius: 5px;
          background-color: white;
          display: flex;
          justify-content: space-between;
          @media (max-width: 640px) {
            padding: 1rem;
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            max-width: 80%;
            @media (max-width: 640px) {
              max-width: 100%;
            }
          `}
        >
          <CardTitle
            {...titleProps}
            variant="h4"
            css={css`
              margin-bottom: 0.5rem;
            `}
          />
          <Excerpt variant="description" lines={2}>
            {text}
          </Excerpt>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Button
            variant="primary"
            color="yellow"
            css={css`
              min-width: ${theme.getLength(23)};
              min-height: ${theme.getLength(7)};
            `}
          >
            {t("search.showMore")}
          </Button>
        </div>
      </CardContent>
    </TeaserCard>
  );
}
